import { useState } from "react";

const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

const useOcr = () => {
	const [updatedFields, setUpdatedFields] = useState({});

	const triggerUpdatedAnimation = async inputName => {
		setUpdatedFields(prev => ({ ...prev, [inputName]: true }));
		setTimeout(() => {
			setUpdatedFields(prev => {
				const newState = { ...prev };
				delete newState[inputName];
				return newState;
			});
		}, 750);
	};

	const updateField = (fieldName, value, form) => {
		if (!value || !fieldName) {
			return;
		}
		form.setValue(fieldName, value);
		triggerUpdatedAnimation(fieldName);
	};

	const testDate = value => {
		return datePattern.test(value);
	};

	return {
		updatedFields,
		setUpdatedFields,
		triggerUpdatedAnimation,
		updateField,
		testDate,
	};
};
export default useOcr;
