import { DownloadIcon, UploadIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useHttp } from "components/hooks/useHttp";
import React, { useState } from "react";
import { useController } from "react-hook-form";
import { FaSpinner } from "react-icons/fa";
import { classNames } from "util/common";

function FileField({
	control,
	name,
	storageName = "", // ["manufacturers", "marketers", "products", "stores", "documents"]
	label = "בחר קובץ",
	placeholder = "בחר קובץ",
	className = "",
	icon,
	accept = "image/png, image/jpeg, image/jpg, application/pdf",
	required = false,
	hasPreview = true,
	setImageSize = f => {},
	sizeLimit = 7000,
	disabled = false,
	isEndUserCartPatientDocs = false,
	setOcrData = f => {},
	documentType,
	onNewFile = f => {},
	errors,
	setValue,
	previewUrl,
	date,
}) {
	const [selectedFile, setSelectedFile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { $upload } = useHttp();
	const {
		field: { onChange, value },
	} = useController({
		name,
		control,
		rules: { required: required },
	});
	const handleFileChange = async e => {
		if (loading) {
			return;
		}
		const files = e?.target?.files || [];
		if (!files.length) {
			return;
		}
		const file = files[0];
		setSelectedFile(null); // Clear selectedFile
		setValue(name, null); // Reset form field
		setSelectedFile(file);
		const reader = new FileReader();
		reader.readAsDataURL(file);
		filePicked(file);
		onNewFile();
	};

	const filePicked = async file => {
		if (!file) {
			return;
		}
		// TODO - implement over
		const file_size = (file.size / 1024).toFixed(2);

		const mb = Math.round(sizeLimit / 1024);

		console.log({ file_size });

		if (file_size > sizeLimit) {
			// Don't upload if file to big
			setError({
				title: "משקל הקובץ חורג מהמגבלה.",
				message: `ניתן להעלות עד ${mb}MB.`,
				code: 400,
			});
		} else {
			try {
				setSelectedFile(null);
				setLoading(true);
				const res = await $upload(file, storageName, documentType);
				if (res.ok) {
					onChange(res?.data?.url);
					setValue("previewUrl", res?.data?.presigned_url);
					setOcrData(res?.data);
					if (!!setImageSize && !!file.size) {
						setImageSize(file_size);
					}
					setError(null);
				} else {
					setError(res.error);
					throw res.error.message;
				}
			} catch (error) {
				console.log("קרתה שגיאה בהעלאת הקובץ", error);
			} finally {
				setLoading(false);
			}
		}
	};

	if (!storageName || !name || !control) {
		return null;
	}

	const acceptedExtensions = ["jpg", "jpeg", "png"];
	const isValueImage = acceptedExtensions.some(extension =>
		previewUrl?.includes(extension),
	);
	return (
		<label htmlFor={name} className={className}>
			<span className="font-semibold">{label ? label : "העלאת קבצים"}</span>
			{value ? (
				<div
					className={classNames(
						"w-full cursor-pointer p-4 bg-jane-300 border-jane-500 rounded-md border ",
						!value && "border-dashed",
					)}
					style={{
						backgroundImage: `url(${
							isValueImage ? previewUrl : "/file-placeholder.svg"
						})`,
						backgroundSize: "cover",
						backgroundPosition: isValueImage ? "center" : "0 15px ",
						backgroundRepeat: "no-repeat",
					}}
				>
					<span className="flex flex-col bg-jane-900 bg-opacity-60 backdrop-blur-[2px] text-white p-2 w-fit mx-auto rounded-md items-center gap-1 transition-all hover:bg-opacity-75">
						{!!selectedFile ? (
							selectedFile.name
						) : !!value ? (
							<>
								<span className="flex items-center gap-1">
									<CheckCircleIcon className="w-5 h-5" /> הועלה בהצלחה!
								</span>
								<span className="underline">העלאת קובץ אחר</span>
							</>
						) : !!placeholder ? (
							placeholder
						) : (
							"לחץ כאן כדי להעלות קובץ"
						)}
					</span>
				</div>
			) : (
				<div
					className={classNames(
						"w-full cursor-pointer p-4 bg-jane-200 border-jane-500 transition-all rounded-md border border-dashed",
						!!loading ? "animate-pulse bg-jane-400 cursor-wait" : "bg-jane-200",
						errors?.[name] && "!border-red-500 bg-red-100",
					)}
				>
					<span className="flex flex-col bg-jane-900 bg-opacity-60 text-white p-2 w-fit mx-auto rounded-md items-center gap-1">
						{loading ? (
							<>
								<FaSpinner className="w-5 h-5 animate-spin mr-10 ml-12" />
								טוען...
							</>
						) : (
							<>
								<span className="w-5 h-5">
									{!!icon ? icon : <UploadIcon />}
								</span>
								{!!selectedFile
									? selectedFile.name
									: !!placeholder
									? placeholder
									: "לחץ כאן כדי להעלות קובץ"}
							</>
						)}
					</span>
				</div>
			)}

			<input
				className="hidden"
				disabled={loading || disabled}
				type="file"
				id={name}
				onChange={handleFileChange}
				accept={accept}
			/>
			<div className="flex justify-between text-jane-500 items-center">
				{previewUrl && (
					<span
						onClick={e => {
							window.open(previewUrl);
						}}
						className="px-2 rounded-md underline flex items-center gap-1 w-fit cursor-pointer transition-all  hover:bg-jane-100"
					>
						<DownloadIcon className="w-5 h-5" />
						הורדת קובץ
					</span>
				)}
				{date && <span className="text-sm">הועלה ב-{date}</span>}
			</div>
		</label>
	);
}

export default FileField;
