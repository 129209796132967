function InfoCell({ label, value, className = "" }) {
	return (
		value && (
			<div className={"flex flex-col " + className}>
				<span className="font-semibold">{label}</span>
				<span>{value}</span>
			</div>
		)
	);
}
export default InfoCell;
